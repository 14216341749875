<script lang="ts">
  export let twitchUsername: string

  let data = {}
  let user
  let creatureEntries
  let selectedCreature
  let error

  // Get data.
  async function getData () {
    let req
    try {
      req = await fetch(`${process.env.API_ENDPOINT}/data`)
  
      if (req.status !== 200) {
        const err = await req.text()
        error = err
        return false
      }
    } catch (err) {
      error = err.message
      return false
    }

    const res = await req.json()

    const objName = Object.entries(res.creatures.names)
    const objNameLength = objName.length

    for (let index = 0; index < objNameLength; index++) {
      const [key, value] = objName[index]
      data[key] = {
        name: value,
        description: res.creatures.description[key]
      }
    }

    return true
  }

  // Information about the current requested user.
  async function getUserData () {
    let req
    try {
      req = await fetch(`${process.env.API_ENDPOINT}/aquarium/${twitchUsername}`)

      if (req.status !== 200) {
        const err = await req.text()
        error = `User not found! (${req.status} ${err})`
        return false
      }
    } catch (err) {
      error = err.message
      return false
    }

    const res = await req.json()

    user = res.user
    creatureEntries = res.creatureEntries

    return true
  }

  getData().then((res) => res ? getUserData() : undefined)
</script>

<div class="text-center">
  {#if user}
    <h2 class="text-2xl">{user.twitch_username}'s Aquarium</h2>
    <div class="md:grid md:grid-cols-4 md:gap-3 my-3">
      {#each creatureEntries as entry}
        <article class="{(entry.creature_id === user.current_creature_id ? 'active' : '') + 'bg'}" on:click="{() => selectedCreature = entry}" role="button">
          <h3 class="text text-xl font-bold">{data[entry.creature_id] ? data[entry.creature_id].name : 'Unknown Fish'}</h3>
          <img src="/img/creatures/{entry.creature_id}.png" alt="Image of {data[entry.creature_id] ? data[entry.creature_id].name : 'Unknown Fish'}.">
          <div class="flex flex-col">
            {#if typeof entry.level === 'string'}
              <p><strong>Level:</strong> <span>{entry.level}</span></p>
            {/if}
            {#if typeof entry.attack === 'string'}
              <p><strong>Attack:</strong> <span>{entry.attack}</span></p>
            {/if}
            {#if typeof entry.defense === 'string'}
              <p><strong>Defense:</strong> <span>{entry.defense}</span></p>
            {/if}
            <!-- Add new fields -->
            <!--
              {#if typeof entry.luck === 'string'}
                <p><strong>Luck:</strong> <span>{entry.luck}</span></p>
              {/if}
            -->
          </div>
        </article>
      {/each}
    </div>
  {:else if error}
    <h2 class="text-2xl font-bold text-red-500">Something went wrong.</h2>
    <pre>{ error }</pre>
  {:else}
    <h2 class="text-2xl font-bold">Loading...</h2>
  {/if}
</div>
<hr>
{#if selectedCreature}
  <h2 class="text-2xl mb-3 font-bold">{data[selectedCreature.creature_id].name}</h2>
  <div class="bg p-4">
    <cite>{data[selectedCreature.creature_id].description}</cite>
  </div>
  <hr>
{/if}

<style lang="postcss">
  article {
    @apply p-4 rounded bg-slate-600 text-white cursor-pointer;
  }
  article.active {
    @apply border-sky-500 border-8;
  }
  pre {
    @apply w-full bg-black bg-opacity-50 text-white rounded p-4 my-3 text-left;
  }
</style>
