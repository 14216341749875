<script lang="ts">
  import Commands from '../commands.svelte'
  import AquariumQuery from './index.svelte'
</script>

<div class="bg p-4">
  <slot />
  <Commands />
  <hr>
  <AquariumQuery />
</div>
