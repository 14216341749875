<script lang="ts">
  import { goto, url } from '@roxi/routify'

  function lookupUser () {
    const { twitch_username: twitchUsername } = this.elements

    window.location.href = $url('/aquarium/:twitchUsername', { twitchUsername: twitchUsername.value })
    window.location.reload()
  }
</script>

{#if $url() === '/aquarium'}
  <p class="font-bold text-red-500">Please insert search term.</p>
{/if}
<form on:submit|preventDefault="{lookupUser}" class="flex flex-row gap-1">
  <div class="flex-grow">
    <input type="text" class="input text w-full" name="twitch_username" id="twitch_username"/>
  </div>
  <button class="button">
    <span class="text">Lookup user inventory</span>
  </button>
</form>