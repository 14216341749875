
/**
 * @roxi/routify 2.18.4
 * File generated Sun Jan 16 2022 20:31:12 GMT+0100 (Central European Standard Time)
 */

export const __version = "2.18.4"
export const __timestamp = "2022-01-16T19:31:12.457Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports
import __fallback from '../src/pages/_fallback.svelte'
import _aquarium__twitchUsername from '../src/pages/aquarium/[twitchUsername].svelte'
import _aquarium_index from '../src/pages/aquarium/index.svelte'
import _aquarium__layout from '../src/pages/aquarium/_layout.svelte'
import _commands from '../src/pages/commands.svelte'
import _index from '../src/pages/index.svelte'

//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => __fallback
    },
    {
      "isDir": true,
      "children": [
        {
          "isPage": true,
          "path": "/aquarium/:twitchUsername",
          "id": "_aquarium__twitchUsername",
          "component": () => _aquarium__twitchUsername
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/aquarium/index",
          "id": "_aquarium_index",
          "component": () => _aquarium_index
        }
      ],
      "isLayout": true,
      "path": "/aquarium",
      "id": "_aquarium__layout",
      "component": () => _aquarium__layout
    },
    {
      "isPage": true,
      "path": "/commands",
      "id": "_commands",
      "component": () => _commands
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => _index
    }
  ],
  "path": "/"
}


export const {tree, routes} = buildClientTree(_tree)

