<script lang="ts">
  import Commands from './commands.svelte'
  import AquariumQuery from './aquarium/index.svelte'
</script>

<section class="bg p-4">
  <!-- TODO: Explain website for users.
  <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo praesentium maiores perferendis nemo ex quidem sequi modi quibusdam excepturi quae consequatur, exercitationem esse ipsa quisquam eum deserunt cumque? Ea, voluptatum maiores nesciunt at impedit, id quia eveniet hic sit reiciendis eos dolore odio voluptas ad aut tempora provident, pariatur earum.</p>
  <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Beatae odio distinctio, quis culpa consectetur, excepturi possimus in dolor, eum ipsam vitae expedita repellendus animi dignissimos praesentium iste accusamus exercitationem. Ducimus voluptates blanditiis ipsam, iusto ipsum, odio ratione magni adipisci error cupiditate praesentium aliquam dolores eum doloremque sint sit libero totam!</p>
  <hr>
  -->
  <Commands />
  <hr>
  <AquariumQuery />
</section>
