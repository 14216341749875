<script lang="ts">
  import { Router, url } from '@roxi/routify'
  import { routes } from '../.routify/routes'
</script>

<main class="container mx-auto my-3">
  <h1 class="text-center text text-4xl font-bold my-3">
    <a href="/">RamsReef's Fishimon</a>
  </h1>
  <hr>
  <Router config={{useHash: true}} {routes} />
</main>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  html {
    @apply text-slate-800 dark:bg-slate-800 dark:text-white text-lg;
    background-image: url('/img/bubbles.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  hr {
    @apply my-6 bg-opacity-50 border-slate-900 dark:border-slate-600;
  }

  .button {
    @apply bg-slate-900 text-white dark:bg-slate-600 p-2 rounded;
  }

  .input {
    @apply p-2 border-slate-900 border-2 rounded;
  }

  .input.text {
    @apply text-slate-900;
  }

  .bg {
    @apply bg-opacity-75 bg-white dark:bg-opacity-50 dark:bg-black;
  }
</style>
